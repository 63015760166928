<!-- 分步骤add -->
<template>
  <ykc-form class="el-form-wrap">
    <el-steps :active="active" align-center finish-status="success" v-if="!isEdit">
      <el-step title="互联客户密钥" />
      <el-step title="商户密钥" />
    </el-steps>
    <div>
      <ykc-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm0"
        class="el-form-wrap"
        v-if="!isEdit"
        v-show="active === 0 && !isEdit">
        <ykc-form-item :label="'互联机构名称'" prop="orgName">
          <ykc-dropdown
            :remote="true"
            :disabled="id != null"
            v-model="ruleForm.orgName"
            placeholder="请输入互联机构名称"
            :isObject="true"
            :configSet="configSet"
            :searchMaxLength="1000"
            @change="selectOrg"
            :data="mainOrgList || []"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="机构密钥" prop="reqSecretKey">
          <ykc-input
            placeholder="请输入机构密钥"
            maxlength="30"
            v-model="ruleForm.reqSecretKey"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="签名密钥" prop="reqSignKey">
          <ykc-input
            placeholder="请输入签名密钥"
            maxlength="30"
            v-model="ruleForm.reqSignKey"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="初始化向量" prop="reqInitializationVector">
          <ykc-input
            placeholder="请输入初始化向量"
            maxlength="30"
            v-model="ruleForm.reqInitializationVector"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="消息密钥" prop="reqDataEncryptionKey">
          <ykc-input
            placeholder="请输入消息密钥"
            maxlength="30"
            v-model="ruleForm.reqDataEncryptionKey"></ykc-input>
        </ykc-form-item>
      </ykc-form>
      <ykc-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm1"
        class="el-form-wrap"
        v-if="active === 1 || isEdit">
        <ykc-form-item :label="'推送方名称'" prop="operatorName">
          <ykc-dropdown
            :remote="true"
            :disabled="id != null"
            v-model="ruleForm.operatorName"
            placeholder="下拉选择互联机构"
            :isObject="true"
            :configSet="operatorConfigSet"
            :searchMaxLength="1000"
            @change="selectOperator"
            :data="operatorList || []"></ykc-dropdown>
        </ykc-form-item>
        <ykc-form-item label="推送方密钥" prop="proSecretKey">
          <ykc-input
            placeholder="请输入推送方密钥"
            maxlength="30"
            v-model="ruleForm.proSecretKey"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="签名密钥" prop="proSignKey">
          <ykc-input
            placeholder="请输入签名密钥"
            maxlength="30"
            v-model="ruleForm.proSignKey"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="初始化向量" prop="proInitializationVector">
          <ykc-input
            placeholder="请输入初始化向量"
            maxlength="30"
            v-model="ruleForm.proInitializationVector"></ykc-input>
        </ykc-form-item>
        <ykc-form-item label="消息密钥" prop="proDataEncryptionKey">
          <ykc-input
            placeholder="请输入消息密钥"
            maxlength="30"
            v-model="ruleForm.proDataEncryptionKey"></ykc-input>
        </ykc-form-item>
      </ykc-form>
    </div>
  </ykc-form>
</template>
<script>
  import regexpObj from '@/utils/regexp';
  import { filterByIdList } from '@/utils/index';
  import { cipherUnione, operatorData } from '@/service/apis';

  export default {
    props: {
      active: {
        type: Number,
        default: 0,
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
      id: {
        type: Number,
      },
    },
    name: 'AddOrEdit',
    data() {
      return {
        mainOrgList: [],
        operatorList: [],
        // 机构下拉框数据显示配置
        configSet: {
          label: 'name',
          value: 'name',
        },
        operatorConfigSet: {
          label: 'name',
          value: 'name',
        },
        ruleForm: {
          // 互联机构名称
          orgId: '',
          // 机构密钥
          reqSecretKey: '',
          // 签名密钥
          reqSignKey: '',
          // 初始化向量
          reqInitializationVector: '',
          // 消息密钥
          reqDataEncryptionKey: '',
          // 商户名称
          operatorId: '',
          // 商户密钥
          proSecretKey: '',
          // 签名密钥
          proSignKey: '',
          // 初始化向量
          proInitializationVector: '',
          // 消息密钥
          proDataEncryptionKey: '',
        },
        rules: {
          orgId: [
            { required: true, message: '请输入机构名称', trigger: 'blur' },
            {
              trigger: 'blur',
              pattern: regexpObj.regexp.input.nameType.regexp,
              message: regexpObj.regexp.input.nameType.errorTips.error('机构名称'),
            },
          ],
          reqSecretKey: [{ required: true, message: '请输入机构密钥', trigger: 'change' }],
          reqSignKey: [{ required: true, message: '请输入签名密钥', trigger: 'blur' }],
          reqInitializationVector: [
            { required: true, message: '请输入初始化向量', trigger: 'blur' },
          ],
          reqDataEncryptionKey: [{ required: true, message: '请输入消息密钥', trigger: 'blur' }],

          operatorId: [{ required: true, message: '请输入选择商户', trigger: 'blur' }],
          proSecretKey: [{ required: true, message: '请输入商户密钥', trigger: 'blur' }],
          proSignKey: [{ required: true, message: '请输入签名密钥', trigger: 'blur' }],
          proInitializationVector: [
            { required: true, message: '请输入初始化向量', trigger: 'blur' },
          ],
          proDataEncryptionKey: [{ required: true, message: '请输入消息密钥', trigger: 'blur' }],
        },
        // 详情数据
        detail: {},
        dictionary: {},
        organizations: [],
      };
    },
    created() {
      // 编辑回显
      if (this.id) {
        cipherUnione
          .detail({ id: this.id })
          .then(res => {
            this.ruleForm = {
              ...this.ruleForm,
              ...res,
            };
          })
          .catch(() => {});
      }
      this.queryMainOrgWithOutZdlList();
    },
    computed: {
      /**
       * 车队列表默认展示符
       * */
      formData() {
        const formData = {
          ...this.ruleForm,
        };
        return formData;
      },
    },
    methods: {
      filterByIdList,
      // 获取机构下拉数据
      queryMainOrgWithOutZdlList() {
        cipherUnione.zdlOrgList({}).then(res => {
          this.mainOrgList = res;
        });
        operatorData.userOperatorList({}).then(res => {
          this.operatorList = res;
        });
      },
      selectOrg(e) {
        console.log(JSON.stringify(e));
        this.ruleForm.orgId = e.id;
      },
      selectOperator(e) {
        console.log(JSON.stringify(e));
        this.ruleForm.operatorId = e.operatorId;
      },
      /** 导入电站 */
      stationUpload(file, fileList, isCheck) {
        if (isCheck) {
          cipherUnione
            .station_upload({ file: file.raw })
            .then(res => {
              // console.log('res---站点导入', res);
              this.formData.configIds = res.stationIds;
            })
            .catch(() => {
              this.$refs.stationUpload.fileList = [];
            });
          this.$refs.ruleForm1.validateField('stationUpload');
        }
      },
      /** 表单提交事件，用于父组件定义的"完成"或"保存"按钮点击事件 */
      submitForm() {
        return new Promise(resolve => {
          this.validateAllForm().then(() => {
            if (!this.id) {
              cipherUnione
                .add(this.formData)
                .then(res => {
                  console.log('新增', res);
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            } else {
              cipherUnione
                .edit(this.formData)
                .then(res => {
                  console.log('编辑', res);
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            }
          });
        });
      },
      /** 校验当前表格是否通过 */
      validateForm() {
        return new Promise((resolve, reject) => {
          this.$refs[`ruleForm${this.active}`].validate(valid => {
            if (valid) {
              resolve();
            } else {
              reject();
            }
          });
        });
      },
      validateAllForm() {
        return new Promise((resolve, reject) => {
          Promise.all(
            Object.keys(this.$refs)
              .filter(o => o.includes('ruleForm'))
              .map(o => {
                return new Promise((_resolve, _reject) => {
                  this.$refs[o].validate(valid => {
                    if (valid) {
                      _resolve();
                    } else {
                      _reject();
                    }
                  });
                });
              })
          )
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            });
        });
      },
    },
  };
</script>
