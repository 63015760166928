<!--
 * @ Author: Clark
 * @ Create Time: 2023-02-05 20:20:57
 * @ Modified by: Your name
 * @ Modified time: 2023-03-03 11:00:55
 * @ Description: 司机管理>互联互通客户>互联推送管理
 -->

<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        operateFixedType="right"
        :data="tableData"
        :title="tableTitle"
        :columns="tableColumns"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <template #headerButtons>
          <div class="table-header-button">
            <template v-for="(btn, i) in tableButtons">
              <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handleClick">
                {{ btn.text }}
              </ykc-button>
            </template>
          </div>
        </template>
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :current-page.sync="pageInfo.current"
          @current-change="handlePaginationCurrentChange"
          :page-size.sync="pageInfo.size"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
    <ykc-drawer
      :show.sync="showDrawer"
      :title="drawerTitle"
      :on-close="onAddDrawerClose"
      :before-cancel="onAddDrawerCancel"
      ensure-txt="保存">
      <template #footer>
        <div class="drawer-button-wrap">
          <template v-for="(btn, i) in drawerButtons">
            <ykc-button :type="btn.type" v-if="btn.enabled()" :key="i" @click="btn.handler">
              {{ btn.label }}
            </ykc-button>
          </template>
        </div>
      </template>
      <div class="drawer-main-body" v-if="showDrawer">
        <AddOrEdit
          v-if="drawerType === 'edit'"
          ref="addOrEdit"
          :id="id"
          :isEdit="isEdit"
          :active="stepsIndex"></AddOrEdit>
        <Config
          v-if="drawerType === 'config'"
          ref="config"
          :id="id"
          :name="name"
          :isEdit="isEdit"
          :active="stepsIndex"></Config>
      </div>
    </ykc-drawer>
  </scroll-layout>
</template>

<script>
  import YkcDialog from '@/components/ykc-ui/dialog/index';
  import AddOrEdit from './AddOrEdit.vue';
  import Config from './Config.vue';
  import { cipherUnione } from '@/service/apis';
  import { offlineExport, code } from '@/utils';

  export default {
    name: 'MotorcadeMainAccountList',
    components: {
      AddOrEdit,
      Config,
    },
    data() {
      return {
        id: '',
        name: '',
        drawerType: '',
        isEdit: false,
        showDrawer: false,
        stepsIndex: 0,
        maxStepIndex: 2,
        drawerButtons: [
          {
            label: '取消',
            type: 'plain',
            enabled: () => true,
            handler: () => {
              YkcDialog({
                dialogType: 'feedback',
                showTitle: false,
                showFooter: true,
                desc: '取消后操作将不被保存，确认是否取消？',
                onCancel: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                },
                onConfirm: dialogDone => {
                  dialogDone(); // 隐藏弹窗
                  this.showDrawer = false; // 隐藏抽屉
                },
              });
            },
          },
          {
            label: '上一步',
            enabled: () => {
              return this.stepsIndex !== 0;
            },
            disabled: () => false,
            handler: () => {
              this.stepsIndex--;
            },
          },
          {
            label: '下一步',
            enabled: () => {
              return this.stepsIndex !== this.maxStepIndex;
            },
            disabled: () => false,
            handler: () => {
              this.$refs.addOrEdit.validateForm().then(() => {
                this.stepsIndex++;
              });
            },
          },
          {
            label: '保存',
            enabled: () => {
              return this.stepsIndex === this.maxStepIndex;
            },
            disabled: () => {
              return this.isRequest;
            },
            handler: () => {
              this.isRequest = true;
              if (this.drawerType === 'edit') {
                this.$refs.addOrEdit
                  .submitForm()
                  .then(() => {
                    this.stepsIndex = 0;
                    this.showDrawer = false;
                    this.isRequest = false;
                    this.searchTableList();
                  })
                  .finally(() => {
                    this.isRequest = false;
                  });
              } else {
                this.$refs.config
                  .submitForm()
                  .then(() => {
                    this.stepsIndex = 0;
                    this.showDrawer = false;
                    this.isRequest = false;
                    this.searchTableList();
                  })
                  .finally(() => {
                    this.isRequest = false;
                  });
              }
            },
          },
        ],
        tableButtons: [
          {
            id: '0',
            text: '新增',
            type: 'plain',
            enabled: () => code('driver:connect:push:add'),
            handleClick: () => {
              this.add(null);
            },
          },
          {
            id: '1',
            text: '导出',
            type: 'plain',
            enabled: () => code('driver:connect:push:export'),
            handleClick: () => {
              this.doExportFile();
            },
          },
        ],
        tableData: [],
        tableTitle: '互联密钥管理',
        tableColumns: [
          { label: '互联客户', prop: 'orgName', minWidth: '150px' },
          { label: '商户', prop: 'operatorName', minWidth: '100px' },
        ],
        tableOperateButtons: [
          {
            id: '0',
            enabled: () => code('driver:connect:push:push'),
            text: '站点推送',
            handleClick: (btn, { row }) => {
              this.stepsIndex = 0;
              this.maxStepIndex = 0;
              this.showAddDrawer = true;
              this.isEdit = false;
              this.id = row.id;
              this.name = row.orgName;
              this.drawerType = 'config';
              this.showDrawer = true;
            },
          },
          {
            id: '1',
            enabled: () => code('driver:connect:push:edit'),
            text: '编辑',
            handleClick: (btn, { row }) => {
              this.add(row.id);
            },
          },
          {
            id: '2',
            enabled: () => code('driver:connect:push:del'),
            text: '删除',
            handleClick: (btn, { row }) => {
              this.delRow(row);
            },
          },
          {
            enabled: () => code('driver:connect:push:detail'),
            id: '2',
            text: '详情',
            handleClick: (btn, { row }) => {
              this.detailLink(row);
            },
          },
        ],
        pageInfo: {
          current: 0,
          size: 10,
        },
        searchParams: {
          orgName: '',
          operatorName: '',
        },
      };
    },
    created() {
      this.searchTableList();
    },
    computed: {
      drawerTitle() {
        if (this.drawerType === 'import') {
          return '批量导入';
        }
        // eslint-disable-next-line no-nested-ternary
        return this.drawerType === 'config' ? '站点推送' : this.isEdit ? '密钥编辑' : '密钥新增';
      },
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'orgName',
            label: '互联客户',
            placeholder: '请输入互联客户',
          },
          {
            comName: 'YkcInput',
            key: 'operatorName',
            label: '商户',
            placeholder: '请输入商户名称',
          },
        ];
      },
    },
    methods: {
      detailLink(row) {
        this.$router.push({
          path: '/customerCenter/customerUnion/cipherUnion/cipherUnionDetail',
          query: {
            id: row.id,
          },
        });
      },
      contractDetailLink(row) {
        this.$router.push({
          path: '/marketingCenter/labelUsers/details',
          query: {
            id: row.contractId,
          },
        });
      },
      add(id) {
        this.stepsIndex = 0;
        this.maxStepIndex = 1;
        this.showAddDrawer = true;
        this.isEdit = false;
        this.id = id || null;
        this.drawerType = 'edit';
        this.showDrawer = true;
      },
      /**
       * @desc 删除
       * */
      delRow(row) {
        YkcDialog({
          showTitle: false,
          showFooter: true,
          dialogType: 'feedback',
          desc: `是否确认删除？删除之后无法恢复请谨慎操作`,
          onConfirm: done => {
            cipherUnione
              .delete({ id: row.id })
              .then(res => {
                console.log(res);
                done();
                this.$message({
                  message: `删除成功`,
                  type: 'success',
                });
                this.searchTableList();
              })
              .catch(err => {
                console.log(err);
              });
            done();
          },
          onCancel: done => {
            console.log(done);
            done();
          },
        });
      },
      handleSpecialCountClick(row) {
        const routeUrl = this.$router.resolve({
          path: '/priceConfig/specialPurchasePrice',
          query: {
            stationId: row.stationId,
          },
        });
        window.open(routeUrl.href, '_blank');
      },
      changeStateSearch() {
        this.pageInfo.current = 0;
        this.searchTableList();
      },
      /** 根据条件查询数据 */
      searchTableList() {
        Promise.all([
          cipherUnione.list({
            ...this.pageInfo,
            ...this.searchParams,
          }),
        ]).then(([res1]) => {
          this.tableData = res1.records;
          this.pageInfo.total = res1.total || 0;
        });
      },
      /**
       * @desc 导出文件 - 车队主账号列表
       * */
      doExportFile() {
        console.log('doExport');
        offlineExport(
          {
            downloadType: 'secret',
            jsonNode: {
              ...this.searchParams,
            },
          },
          this.pageInfo.total
        );
      },
      /** 批量导入文件 */
      doImportFile() {
        this.drawerType = 'import';
        this.isEdit = true;
        this.showDrawer = true;
      },
      onAddDrawerClose(done) {
        this.stepsIndex = 0;
        done();
      },
      /**
       * @desc 点击抽屉取消按钮 callback
       * */
      onAddDrawerCancel(drawerDone) {
        YkcDialog({
          dialogType: 'feedback',
          showTitle: false,
          showFooter: true,
          desc: '取消后操作将不被保存，确认是否取消？',
          onCancel: done => {
            done();
          },
          onConfirm: done => {
            done();
            drawerDone();
            if (this.isEdit) {
              this.isEdit = false;
            }
          },
        });
      },
      /**
       * @desc 点击页面左上角按钮 callback
       * */
      handlePageButtonClick(button, event) {
        if (typeof button.handleClick === 'function') {
          button.handleClick(button, event);
        }
      },
      /**
       * @desc 点击查询按钮
       * */
      handleSearchConfirm(form) {
        this.pageInfo.current = 0;
        Object.assign(this.searchParams, form);
        this.searchTableList();
      },
      /**
       * 点击清空查询按钮
       * */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },
      /**
       * @desc 分页 change 事件监听器
       * */
      handlePaginationCurrentChange(current) {
        this.pageInfo.current = current;
        this.searchTableList();
      },
      /**
       * 分页 size  change  事件监听器
       * */
      handlePaginationSizeChange(size) {
        this.pageInfo.current = 1;
        this.pageInfo.size = size;
        this.searchTableList();
      },
      /**
       * 重置 form data
       * */
      resetFormData() {
        this.searchParams = {
          orgName: '',
          operatorName: '',
        };
      },
    },
  };
</script>
<style lang="scss" scoped>
  .link {
    color: blue;
    cursor: pointer;
  }
  .el-radio-group .item {
    border: 0;
  }
</style>
