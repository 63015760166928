<!-- 分步骤add -->
<template>
  <ykc-form class="el-form-wrap">
    <div>
      <ykc-form :model="ruleForm" :rules="rules" ref="ruleForm" class="el-form-wrap">
        <ykc-form-item :label="'客户名称'" prop="operatorName">
          <div>{{ ruleForm.operatorName }}</div>
        </ykc-form-item>
        <ykc-form-item label="客户类别" prop="operatorType">
          <div>{{ '流量平台' }}</div>
        </ykc-form-item>
        <ykc-form-item label="电站维度" prop="stationScopeType">
          <ykc-radio
            @change="selectStationScopeType"
            :data="[
              { id: '2', name: '按电站' },
              { id: '4', name: '全部电站' },
            ]"
            v-model="ruleForm.stationScopeType"></ykc-radio>
        </ykc-form-item>
        <ykc-form-item
          prop="stationIdList"
          label="选择电站"
          v-if="ruleForm.stationScopeType == '2'">
          <ykc-tree
            ref="operaTree"
            :data="vehicleList"
            :props="treeProps"
            :nodeKey="treeNodeKey"
            @check-change="handleOperatorTree"></ykc-tree>
        </ykc-form-item>
      </ykc-form>
    </div>
  </ykc-form>
</template>
<script>
  import { treeUtils, unioneOrg } from '@/service/apis';

  export default {
    props: {
      id: {
        type: Number,
      },
      name: {
        type: String,
      },
      active: {
        type: Number,
        default: 0,
      },
      isEdit: {
        type: Boolean,
        default: false,
      },
    },
    name: 'AddOrEdit',
    data() {
      return {
        treeProps: {
          label: 'name',
          children: 'subNodes',
          disabled: 'disabled',
        },
        treeNodeKey: 'stationId',
        vehicleList: [],
        ruleForm: {
          operatorName: '', // 客户名称
          operatorType: '', // 客户类别
          stationScopeType: '', // 电站维度
          stationIdList: [],
        },
        rules: {
          stationScopeType: [{ required: true, message: '请选择电站维度', trigger: 'blur' }],
          stationIdList: [{ required: true, message: '请选择电站', trigger: 'blur' }],
        },
        // 详情数据
        detail: {},
      };
    },
    created() {
      // 编辑回显
      if (this.id) {
        unioneOrg
          .zdlDetail({ configId: `${this.id}` })
          .then((res = {}) => {
            this.ruleForm = {
              ...this.ruleForm,
              ...res,
            };
            this.ruleForm.stationIdList = res.dataIds;
          })
          .catch(() => {});
      }
      this.ruleForm.operatorName = this.name;
      this.findOperatorStationTree();
    },
    computed: {
      formData() {
        const formData = {
          ...this.ruleForm,
          configId: this.id,
          stationScopeType: this.ruleForm.stationScopeType,
          dataIds: this.ruleForm.stationIdList,
        };
        return formData;
      },
    },
    methods: {
      // 获取机构下拉数据
      findOperatorStationTree() {
        treeUtils.operatorStations({}).then(res => {
          this.treeProps = {
            label: 'stationName',
            disabled: 'disabled',
            children: 'stationTreeVOList',
          };
          this.treeNodeKey = 'stationId';
          const listData = res || [];
          const kayMap = { operatorName: 'stationName', operatorId: 'stationId' };
          const setEmpty = (data, keyMap) => {
            const objs = Object.keys(data).reduce((newData, key) => {
              const newKey = keyMap[key] || key;
              newData[newKey] = data[key];
              return newData;
            }, {});
            return objs;
          };
          this.vehicleList = listData.map(item => {
            return setEmpty(item, kayMap);
          });
          if (this.ruleForm?.stationIdList && this.ruleForm?.stationIdList.length > 0) {
            this.$nextTick(() => {
              this.$refs.operaTree.setCheckedKeys(this.ruleForm.stationIdList, true);
            });
          }
        });
      },
      handleOperatorTree(e) {
        console.log(JSON.stringify(e));
        this.ruleForm.stationIdList = this.$refs.operaTree.getCheckedKeys(true);
      },
      selectStationScopeType(e) {
        console.log(e);
        if (e === '4') {
          this.ruleForm.stationIdList = [];
        }
      },
      formatStationTreeData(data) {
        console.log(JSON.stringify(data));
        const list = [];
        for (let i = 0; i < data.length; i++) {
          const station = {
            id: data[i].operatorId, // item.operatorId,
            name: data[i].operatorName,
            subNodes: [],
            disabled: false,
          };
          for (let j = 0; j < data[i].stationTreeVOList.length; j++) {
            const subStation = {
              id: data[i].stationTreeVOList[j].stationId,
              name: data[i].stationTreeVOList[j].stationName,
              disabled: false,
            };
            station.subNodes.push(subStation);
          }
          list.push(station);
        }
        return list;
      },
      /** 表单提交事件，用于父组件定义的"完成"或"保存"按钮点击事件 */
      submitForm() {
        return new Promise(resolve => {
          this.validateAllForm().then(() => {
            if (this.id) {
              unioneOrg
                .zdlEdit(this.formData)
                .then(res => {
                  console.log('新增', res);
                  this.$message({
                    message: '保存成功',
                    type: 'success',
                  });
                  resolve();
                })
                .catch(() => {});
            }
          });
        });
      },
      /** 校验当前表格是否通过 */
      validateForm() {
        return new Promise((resolve, reject) => {
          this.$refs[`ruleForm${this.active}`].validate(valid => {
            if (valid) {
              resolve();
            } else {
              reject();
            }
          });
        });
      },
      validateAllForm() {
        return new Promise((resolve, reject) => {
          Promise.all(
            Object.keys(this.$refs)
              .filter(o => o.includes('ruleForm'))
              .map(o => {
                return new Promise((_resolve, _reject) => {
                  this.$refs[o].validate(valid => {
                    if (valid) {
                      _resolve();
                    } else {
                      _reject();
                    }
                  });
                });
              })
          )
            .then(() => {
              resolve();
            })
            .catch(() => {
              reject();
            });
        });
      },
    },
  };
</script>
